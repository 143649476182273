<template lang="pug">
  .container
    page-title(:title='`Contrato #${(contract && contract.code)} / ${contract && contract.name}`')
      button.button(@click='editContract')
        icon(icon='edit')
      button.button.is-primary.ml-1(@click='create' v-if='isClientContract') Agregar equipo
    b-message(v-if='missingDevices.length')
      | {{ missingDevices.length }} {{ missingDevices.length === 1 ? 'equipo' : 'equipos' }} de este cliente no {{ missingDevices.length === 1 ? 'está asociado' : 'están asociados' }} a ningún contrato.
      a.ml-1(@click='showMissingDevices = !showMissingDevices') {{ showMissingDevices ? 'Ocultar' : 'Mostrar' }} nombres de los equipos
      p(v-if='showMissingDevices') {{ missingDevices.map(device => device.name).join(', ') }}
    .card
      .card-content
        b-tabs
          b-tab-item(label='Facturas')
            invoices-tab(v-if='contract' :contract='contract')
          b-tab-item
            template(slot='header')
              span
                | Equipos
                b-tag(rounded) {{ devices.length }}
            b-table(:data='devices' :loading='isLoading' narrowed hoverable mobile-cards striped searchable)
              b-table-column(field='deviceId' label='Imei' sortable searchable v-slot='props')
                a(@click='editDevice(props.row)' v-if='isClientContract') {{ props.row.deviceId }}
                span(v-else) {{ props.row.deviceId }}
              b-table-column(field='deviceName' label='Nombre' sortable searchable v-slot='props') {{ props.row.deviceName }}
              b-table-column(field='startDate' label='Inicio' sortable searchable v-slot='props') {{ props.row.startDate | date }}
              b-table-column(field='endDate' label='Fin' sortable searchable v-slot='props') {{ props.row.endDate | date }}
              template(slot='empty')
                .no-data-found
    b-modal(
      v-model='deviceFormModalActive'
      has-modal-card
      trap-focus
      aria-role='dialog'
      aria-modal
      :can-cancel='["x"]'
    )
      modal-card(v-if='contractDeviceSelected' :title='`Agregar equipo al contrato`')
        app-form(@submit.prevent='submit' :is-saving='isSaving')
          field(label='Equipo' field='device')
            devices-picker(v-model='contractDeviceSelected.deviceId' only-ids)
          field(label='Inicio' field='startDate')
            app-date(id='startDate' required v-model='contractDeviceSelected.startDate')
          field(label='Fin (opcional)' field='endDate')
            app-date(id='endDate' v-model='contractDeviceSelected.endDate')
    contract-form-modal(v-model='contractFormModalActive' :contract-selected='contract' @saved='getContract')
</template>

<script>
import contractsService from '@/services/contracts.service'
import contractDevicesService from '@/services/contract-devices.service'
import ContractFormModal from '@/components/contracts/ContractFormModal'
import DevicesPicker from '@/components/devices/DevicesPicker'
import InvoicesTab from './blocks/InvoicesTab'
import { contractTypeDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'

export default {
  components: { ContractFormModal, DevicesPicker, InvoicesTab },
  data () {
    return {
      contract: null,
      devices: [],
      missingDevices: [],
      isLoading: false,
      isSaving: false,
      contractTypeDescriptions,
      showMissingDevices: false,
      // modal props
      deviceFormModalActive: false,
      contractDeviceSelected: null,
      contractFormModalActive: false
    }
  },
  created () {
    this.getContract()
  },
  computed: {
    isClientContract () {
      return this.contract && this.contract.clientId
    }
  },
  methods: {
    async getContract () {
      this.isLoading = true
      const { id } = this.$route.params
      const [contractResult, devicesResult, missingDevicesResult] = await Promise.all([
        contractsService.getOne(+id),
        contractDevicesService.getAll({ contractId: id }),
        contractsService.getMissingDevices(+id)
      ])
      if (contractResult && devicesResult && missingDevicesResult) {
        this.contract = contractResult
        this.devices = devicesResult
        this.missingDevices = missingDevicesResult
      }
      this.isLoading = false
    },
    editContract () {
      this.contractFormModalActive = true
    },
    create () {
      this.contractDeviceSelected = {
        contractId: this.contract.id,
        startDate: this.contract.startDate
      }
      this.deviceFormModalActive = true
    },
    editDevice (device) {
      this.contractDeviceSelected = { ...device }
      this.deviceFormModalActive = true
    },
    async submit () {
      const result = await contractDevicesService.save(this.contractDeviceSelected)
      if (result) {
        this.deviceFormModalActive = false
        toastService.show('Dispositivo guardado')
        this.getContract()
      }
    }
  }
}
</script>
