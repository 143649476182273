<template lang="pug">
invoices-table(:invoices='invoices' :is-loading='isLoading')
</template>

<script>
import InvoicesTable from '@/views/invoices/blocks/InvoicesTable'
import invoicesService from '@/services/invoices.service'

export default {
  components: { InvoicesTable },
  props: {
    contract: { type: Object }
  },
  data () {
    return {
      invoices: [],
      isLoading: false
    }
  },
  created () {
    this.getInvoices()
  },
  methods: {
    async getInvoices () {
      this.isLoading = true
      const contractId = this.contract.id
      const result = await invoicesService.getAll({ contractId })
      if (result) {
        this.invoices = result
      }
      this.isLoading = false
    }
  }
}
</script>
